<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl12>
        <!-- Header Section -->
        <v-layout wrap justify-start class="my-3">
          <v-flex
            xs12
            sm3
            md3
            lg12
            text-start
            align-center
            pt-2
            class="headingChurch"
            >Add Church
          </v-flex>
        </v-layout>

        <!-- Card Section -->
        <v-layout wrap justify-center pb-3>
          <v-flex xs12>
            <v-card>
              <v-card-text>
                <v-form ref="addForm" v-model="formValid">
                  <v-layout wrap justify-start px-2>
                    <v-flex xs12>
                      <v-layout align-center>
                        <v-flex>
                          <span class="label">
                            Church Name <span style="color: red;">*</span>
                          </span>
                        </v-flex>
                      
                      </v-layout>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="name"
                        :rules="nameRules"
                        @keyup.enter="showMapDialog"
                        append-icon="mdi-magnify"
                        @click:append="showMapDialog"
                        placeholder="Church name with location : ( St.Joseph's Church Koovappally )"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-dialog v-model="mapDialog" max-width="800px" scrollable>
                      <v-card>
                        <v-card-title class="headline">Map</v-card-title>
                        <v-card-text>
                          <div ref="map" style="height: 400px"></div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="mapDialog = false"
                            >Continue</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-flex xs12 sm4 md4 lg4 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label"> Latitude<span style="color: red;">*</span> </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="latRules"
                        v-model="lat"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm4 md4 lg4 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label"> Longitude <span style="color: red;">*</span></span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="lon"
                        :rules="lonRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pr-lg-0 pr-sm-0 pr-md-0 pr-0>
                      <span class="label"> Pin Code <span style="color: red;">*</span></span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="postalCode"
                        :rules="pinRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label"> Street <span style="color: red;">*</span></span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="streetRules"
                        v-model="street"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label"> City <span style="color: red;">*</span></span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="cityRules"
                        v-model="city"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label"> State<span style="color: red;">*</span> </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="stateRules"
                        v-model="state"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 pr-lg-0 pr-sm-0 pr-md-0 pr-0>
                      <span class="label"> Country <span style="color: red;">*</span></span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="countryRules"
                        v-model="country"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-divider class="mt-2"></v-divider>
                    </v-flex>

                    <v-flex xs12 sm6 md6 lg6 pr-lg-6 pr-sm-6 pr-md-6 pr-0 pt-4>
                      <span class="label"> Phone </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="phoneRules"
                        v-model="contactNumber"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      xs12
                      sm6
                      md6
                      lg6
                      pr-lg-0
                      pr-sm-0
                      pr-md-0
                      pr-0
                      pt-lg-4
                      pt-md-4
                      pt-sm-4
                      pt-0
                    >
                      <span class="label"> Email </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="emailRules"
                        v-model="email"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3 lg3 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label"> Rite <span style="color: red;">*</span></span>
                      <v-select
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="riteRules"
                        outlined
                        dense
                        :items="riteArray"
                        v-model="rite"
                        item-text="name"
                        item-value="_id"  @change="getDiocese"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label"> Diocese <span style="color: red;">*</span></span>
                      <v-select
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="dioceseRules"
                        outlined
                        dense
                        :items="dioceseArray"
                        v-model="diocese"
                        item-text="name"
                        item-value="_id"
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm3 md3 lg3 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label"> Parish Type <span style="color: red;">*</span></span>
                      <v-select
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="parishRules"
                        outlined
                        dense
                        :items="parishArray"
                        item-text="name"
                        item-value="_id"
                        v-model="parishType"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 pr-lg-0 pr-sm-0 pr-md-0 pr-0>
                      <span class="label"> Services <span style="color: red;">*</span></span>
                      <v-select
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="serviceRules"
                        outlined
                        dense
                        :items="servicesArray"
                        v-model="selectedServicesNames"
                        item-text="name"
                        item-value="name"
                        multiple
                      ></v-select>
                    </v-flex>

                    <v-flex xs12>
                      <span class="label"> Upload Image <span style="color: red;">*</span></span>
                      <ImageComp  class="pt-2 text-des"
                        :singleImage="image" :rules="imgRules"
                        @stepper="winStepper" 
                        :componentType="'courseImage'"
                      />
                    </v-flex>

                    <v-flex xs12 pt-3>
                      <span class="label"> Description </span>
                      <v-textarea
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="desc"
                        outlined
                        dense
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions class="py-4 justify-end headline grey lighten-2">
                <v-flex text-end px-3 px-lg-4 px-md-4 px-sm-4>
                  <v-btn color="primary" class="churchbtn" @click="itemadd()"
                    >SAVE CHURCH</v-btn
                  >
                </v-flex>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

    
<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyC8tqEonWgsP6GPES7UpwOin2gcgrzhhwA&libraries=visualization,drawing"  async
defer></script>
      
    <script>
import axios from "axios";
//import ImageComp from "@/components/Common/singleImages";
import ImageComp from "@/components/Common/blessingiImageCrop";

export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      image: "",
      formValid:"",
      courseImage: "",
      riteArray: [],
      languageArray: [],
      parishArray: [],
      dioceseArray: [],
      servicesArray: [
        { _id: "1", name: "Holy Mass" },
        { _id: "2", name: "Confession" },
        { _id: "3", name: "Adoration" },
      ],
      selectedServicesNames: [],
      showSnackBar: false,
      timeout: 5000,
      msg: "",
      name: "",
      lat: "",
      lon: "",
      desc: "",
      street: "",
      city: "",
      country: "",
      state: "",
      postalCode: "",
      contactNumber: "",
      email: "",
      mapDialog: false,
      diocese: "",
      parishType: "",
      rite: "",
      language: "",
      map: null,
      marker: null,
      geocoder: null,
      placesService: null,
      is24x7: false, // Add this line

      nameRules: [(v) => !!v || "Church Name is required"],
      latRules: [(v) => !!v || "Latitude is required"],
      lonRules: [(v) => !!v || "Longitude is required"],
      imgRules: [
  (v) => !!v || "Image is required",
  
],

      pinRules: [(v) => !!v || "Pin code is required"],

      streetRules: [(v) => !!v || "Strret is required"],

      cityRules: [(v) => !!v || "City is required"],
      stateRules: [(v) => !!v || "State is required"],
      countryRules: [
        (v) => !!v || "Country is required",
        (v) => /^[A-Z]/.test(v) || "Country must start with a capital letter",
      ],

      riteRules: [(v) => !!v || "Rite is required"],
      parishRules: [(v) => !!v || "Parish Type is required"],
      dioceseRules: [(v) => !!v || "Diocese is required"],
      serviceRules: [
        (v) =>
          (Array.isArray(v) && v.length > 0) ||
          "At least one service is required",
      ],

      emailRules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],

      phoneRules: [
        (v) => !v || /^\d+$/.test(v) || "Phone number must contain only digits",
        (v) =>
          !v || v.length === 10 || "Phone number must be exactly 10 digits",
      ],
    };
  },
  mounted() {
    this.getLanguage();
    this.getRite();
    this.getParish();
    //this.getDiocese();
  },
  methods: {
    showMapDialog() {
      this.mapDialog = true;
      this.$nextTick(() => {
        this.initMap();
      });
    },
    initMap() {
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat: 9.5916, lng: 76.5226 },
        zoom: 12,
      });
      this.marker = new google.maps.Marker({
        map: this.map,
        draggable: true, // Make the marker draggable
      });

      // Add event listener for when the user stops dragging the marker
      google.maps.event.addListener(this.marker, "dragend", () => {
        const position = this.marker.getPosition();
        this.lat = position.lat();
        this.lon = position.lng();
        this.updateAddressFromLatLng(position);
      });

      this.geocoder = new google.maps.Geocoder();

      // Fetch location if name is already provided
      if (this.name) {
        this.fetchLocation();
      }
    },

    fetchLocation() {
      if (!this.name) return;

      this.geocoder.geocode({ address: this.name }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          const place = results[0];
          const location = place.geometry.location;
          this.lat = location.lat();
          this.lon = location.lng();
          this.updateAddressComponents(place.address_components);

          // Center the map and adjust zoom level
          this.map.setCenter(location);
          this.map.setZoom(15);

          this.marker.setPosition(location);
        } else {
          alert(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    },

    updateAddressFromLatLng(position) {
      this.geocoder.geocode({ location: position }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          const place = results[0];
          this.updateAddressComponents(place.address_components);
        }
      });
    },

    updateAddressComponents(addressComponents) {
      this.street =
        addressComponents.find((component) => component.types.includes("route"))
          ?.long_name || "";
      this.city =
        addressComponents.find((component) =>
          component.types.includes("locality")
        )?.long_name || "";
      this.state =
        addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name || "";
      this.country =
        addressComponents.find((component) =>
          component.types.includes("country")
        )?.long_name || "";
      this.postalCode =
        addressComponents.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || "";

      // Map State Abbreviation to Full State Name if needed
      const stateMap = {
        KL: "Kerala",
        // Add other state mappings as needed
      };
      this.state = stateMap[this.state] || this.state;
    },
    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getDiocese() {
      this.appLoading = true;
      axios({
        url: "/get/diocese/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          riteId: this.rite,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.dioceseArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getParish() {
      this.appLoading = true;
      axios({
        url: "/get/parish/type/list ",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.parishArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    
    winStepper(window_data) {
      if (window_data.type == "courseImage") {
        this.courseImage = window_data.selectedFiles;
      }

    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("image", this.courseImage);

      axios({
        method: "POST",
        url: "/add/church/images",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            formData = new FormData();
            this.courseImage = "";
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    itemadd() {
        const form = this.$refs.addForm;

    // Check if the form and image fields are valid
    if (form.validate() && this.validateImage()) {
      var data = {};
      data["name"] = this.name;
      data["desc"] = this.desc;
      data["city"] = this.city;
      data["state"] = this.state;
      data["country"] = this.country;
      data["street"] = this.street;
      data["diocese"] = this.diocese;
      data["parishType"] = this.parishType;
      data["rite"] = this.rite;
      data["language"] = this.language;
      data["postalCode"] = this.postalCode;
      data["lat"] = this.lat;
      data["lon"] = this.lon;
      (data["services"] = this.selectedServicesNames),
        (data["contactNumber"] = this.contactNumber);
      data["email"] = this.email;
      data["is24x7"] = this.is24x7; // Include this line

      axios({
        url: "/add/new/church",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            var id = response.data._id;
            if (this.courseImage) {
              this.uploadImage(id);
            }
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            // this.name = null;
            // this.desc = null;
            // this.street = null;
            // this.city = null;
            // this.state = null;

            // this.country = null;
            // this.postalCode = null;
            // this.lat = null;
            // this.lon = null;
            // this.services = null;
            // this.contactNumber = null;

            // this.email = null;
            // this.diocese = null;
            // this.parishType = null;
            // this.rite = null;
            // this.language = null;
            // this.is24x7 = false; // Reset the checkbox

            this.$router.push({ name: "churchList" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    }
    else {
        this.msg = "Please fill out all fields correctly, including the image.";
        this.showSnackBar = true;
    }
  },
  validateImage() {
    if (!this.courseImage) {
        this.msg = "Image is required.";
        this.showSnackBar = true;
        return false;
    }
    return true;
},

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // goToAddChurch() {
    //   this.$router.push({ name: 'churchList' });
    // }
  },
};
</script>
    
   
    